.line-separator {
  &.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.separator::before,
  &.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #CED4DA;
  }

  &.separator:not(:empty)::before {
    margin-right: 1em;
  }

  &.separator:not(:empty)::after {
    margin-left: 1em;
  }
}

.heading-text {
  text-transform: uppercase !important;
  font-weight: 700 !important;
  &.btn {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 12px !important;
    span{
      font-size: 12px !important;
    }
  }
}

.button-label {
  width: 100%;
  padding: 15px;
  font-size: 16px !important;
  text-align: center !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  &.primary {
    background-color: $primary !important;
    color: $white !important;
  }

  &.light {
    background-color: $white !important;
    color: $dark !important;
  }

  &.shadow {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
  }
}


label {
  &.heading-text {
    font-size: 12px !important;
  }
}

h6 {
  &.heading-text {
    font-size: 12px !important;
  }
}

h5 {
  &.heading-text {
    font-size: 15px !important;
  }
}

h4 {
  &.heading-text {
    font-size: 17px !important;
  }
}

.bg-dark-grey
{
  background-color: #6C757D !important;
}

.text-smaller
{
  font-size: 0.75rem;
}




@media (min-width: 1000px) {
  .button-label {
    width: 75% !important;
  }
}


