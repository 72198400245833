@font-face {
  font-family: "DMSans";
  src: url("../../assets/fonts/DMSans/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DMSans";
  src: url("../../assets/fonts/DMSans/DMSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "DMSans";
  src: url("../../assets/fonts/DMSans/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "DMSans";
  src: url("../../assets/fonts/DMSans/DMSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: "LuloCleanOne";
  src: url("../../assets/fonts/LuloClean/Lulo-Clean-W01-One.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LuloCleanOne";
  src: url("../../assets/fonts/LuloClean/Lulo-Clean-W01-One-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


body {
  font-family: 'DMSans', sans-serif !important;
}

.heading-text {
  font-family: 'LuloCleanOne', sans-serif !important;
  font-weight: bold;
}