.react-bootstrap-table
{
  th {
    white-space: nowrap !important;
  }
}

.list-table
{
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.requests-table
{
  td {
    font-size: 14px !important;
  }
}