.travel-area-tile {
  padding: 15px;
  margin: 10px 0;

  width: 260px;
  height: 170px;

  border-radius: 8px;
  border: 4px solid $dark;

  .tile-icon {
    margin-top: 1rem;
  }

  .tile-title {
    font-size: 14px;
    line-height: 28px;
  }

  &.disabled {
    background-color: rgba(var(--bs-primary-rgb), .2) !important;
    border: 4px solid rgba(var(--bs-dark-rgb), .2) !important;

    .tile-title {
      color: rgba(var(--bs-dark-rgb), .2) !important;
    }

    svg {
      opacity: 0.2 !important;
    }

    //pointer-events: none !important;
  }
}

.travel-area {
  max-width: 500px;
}

.travel-area-splash {
  min-height: 400px !important;
  background-image: url("./../../../assets/images/family.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.travel-area-splash-image {
  position: absolute;
  bottom: 0;
  right: 10px;
}


@media (max-width: 768px) {
  .travel-area-splash {
    min-height: 200px !important;
  }

  .tile-icon {
    margin-top: 2rem !important;
  }
  .travel-area-tile {
    padding: 5px;
    .tile-title {
      font-size: 10px;
      line-height: 28px;
    }
  }
}