.form-control
{
  background-color: #F4F4F4 !important;
}

.react-select
{
  > div {
    background-color: #F4F4F4 !important;
  }
}

.admin
{
  .form-control
  {
    background-color: inherit !important;
  }

  .react-select
  {
    > div {
      background-color: $white !important;
    }
  }
}

.avatar-upload-input
{
  position: absolute;
  top:4px;
  left: 0;
  opacity: 0;
}