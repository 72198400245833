body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 100%;
}
a {
  text-decoration: none !important;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  .main-container {
    flex-grow: 1 !important;
    display: flex;
    flex-direction: column;
    margin-top: 61px;

    .on-page-notification
    {
      height: 100%;
      .on-page-notification-content
      {
        max-width: 400px;
      }
    }
  }

  .location-form
  {
    max-width: 820px;
  }
}

.travel-grid
{
  svg {
    //height: 50px !important;
    //width: 50px !important;
  }
}

.navbar-nav a {
    flex: 0;
}

.navbar-nav {
    justify-content: center;
    gap: 25px;
}


.navbar-nav a {
    white-space: nowrap;
    font-size: 12px;
    padding: 0px !important;
    margin: 0px !important;
}

.notification-bar {
	margin:0 15px;
}

.room-selection {
    margin-bottom: 0px;
    display: grid;
    gap: 20px;
    margin-top: 0px;
    clear: both;
    float: left;
    width: 100%;
}

.trip-process-form .room-selection {
	margin-top:10px;
	margin-bottom:0px;
}

.room-selection .form-label {
    line-height: 0.8;
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 12px;
}

.room-selection .row {
	margin-left:-5px;
	margin-right:-5px;
}

.room-selection .row > div {
	padding-left:5px;
	padding-right:5px;
}

.room-selection h4 {
    font-family: "LuloCleanOne", sans-serif !important;
    font-size: 17px;
    font-weight: 700;
    margin: 0px;
    line-height: 0.8;
}

.add-room-btn .btn,
.room-selection .btn.btn-danger {
    font-family: "LuloCleanOne", sans-serif !important;
    font-size: 12px;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 700;
	color:#ffffff;
}

.room-selection .row > div {
    display: flex;
    justify-content: start;
    align-items: end;
}

.room-selection .row > div > div {
	width:100%;
}

.room-selection .row > div > div.my-4 {
    margin: 0px !important;
}

.room-selection .btn.btn-danger {
	width:100%;
}

.room-selection .row > div {
    flex: 1;
}

.room-selection .row > div.btn-remove {
    flex: 0;
    min-width: 48px;
}

.room-selection .btn.btn-danger {
    width: 100%;
    max-width: 38px;
}

.room-selection .btn.btn-danger {
    text-indent: -9999px;
    padding: 0px;
    min-width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-selection .btn.btn-danger svg {
    fill: #ffffff;
    width: 16px;
    height: auto;
}

.add-room-btn {
    margin-top: 10px;
    clear: both;
    float: left;
    width: 100%;
}

.room-selection .child-row .child-col {
    margin-bottom: 20px;
    min-width: 33.33333%;
    max-width: 33.33333%;
}

.modal-header {
    border: none;
}

.modal-body .trip-process-form {
    padding: 15px !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.modal-body {
    padding-top: 0px;
}

.modal-header .btn-close {
    width: 30px;
    height: 30px;
    padding: 12px;
    box-sizing: border-box;
    background-size: 12px;
}

.modal-body .holiday-type {
    margin-top: -15px;
}

.modal-body .holiday-type img.card-img-top {
    height: 120px !important;
}

.modal-body .holiday-type .checked-holiday-type {
    top: 24%;
    left: 37%;
}

.trip-process-form .edit-btn svg {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2bd1e;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    float: right;
}

.trip-process-form .edit-btn svg:hover {
	background:#1A1414;
	color:#ffffff;
}

.modal-body .trip-process-form form .modal-buttons.mt-4.d-grid.gap-2 {
    display: block !important;
    clear: both;
    float: left;
    width: 100%;
}

.modal-body .trip-process-form form .mt-4.d-grid.gap-2, .modal-body .trip-process-form .my-4.d-grid.gap-2.d-none {
    display: block !important;
    margin-bottom: 0px !important;
}

.modal-body .trip-process-form form .mt-4.d-grid.gap-2 .d-flex,
.modal-body .trip-process-form .my-4.d-grid.gap-2.d-none .d-flex {
    width: 100%;
    justify-content: space-between !important;
}


.card-layout {
    border: 1px solid #E9E8E8;
    background: #ffffff;
    box-shadow: 4px 4px 20px 0px rgb(0 0 0 / 6%);
	border-radius:8px;
	overflow:hidden;
}

.card-layout .image-slider {
    background: #ffffff;
    padding: 6px 6px 0px 6px;
    margin: 0px !important;
    height: 260px;
    display: block;
    overflow: hidden;
}

.card-layout .image-slider .slick-slider,
.card-layout .image-slider .deal-card {
    background: rgb(26 20 20 / 4%);
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.card-layout .image-slider .deal-card {
	height:100% !important;
}

.card-layout .image-slider .slick-slider .slick-list, .card-layout .image-slider .slick-slider .slick-list .slick-track {
    height: 100%;
}

.card-layout .image-slider img.deal-card {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.card-layout .image-slider button.slick-prev {
	left:15px;
}

.card-layout .image-slider button.slick-next {
	right:15px;
}

.card-layout .image-slider button.slick-arrow {
    width: 34px;
    height: 34px;
    background: #ffffff;
    border-radius: 50%;
    z-index: 2;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.card-layout .image-slider button.slick-arrow:hover {
	opacity:1;
}

.card-layout .image-slider button.slick-arrow:before {
	color:#000000;
}

.card-layout .card-body {
    padding: 0px;
}

.card-layout .card-body .col {
    flex: 0;
    max-width: 100%;
    width: 100%;
}


.card-layout .location-top {
    float: left;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    flex-direction: column;
    gap: 14px;
}

.card-layout .location-top h5 {
    font-size: 12px !important;
    line-height: 1;
    margin: 0px;
}

.card-layout .location-top h5 {
    font-size: 12px !important;
    line-height: 1;
    margin: 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.card-layout .location-top h5 svg {
    height: 12px;
    width: auto;
}

.card-layout .location-top .pricing {
    display: flex;
    justify-content: start;
    align-items: end;
    line-height: 1;
    font-size: 12px;
    color: rgb(26 20 20 / 40%);
    font-weight: 600;
    gap: 5px;
}

.card-layout .location-top .pricing span {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 600;
}

.card-layout .location-top .pricing strong {
    color: #1a1a1a;
    font-size: 28px;
    line-height: 0.8;
}

.card-layout .review-section {
    border-top: 1px solid #E9E8E8;
    border-bottom: 1px solid #E9E8E8;
    background: rgb(26 26 26 / 4%) !important;
    padding: 16px 25px;
}

.review-section > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
    align-items: start;
}

.review-section strong {
    color: #1a1a1a;
    font-size: 12px;
    line-height: 0.8;
    font-weight: 600;
}

.review-section .rating {
    justify-content: start;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #1a1a1a;
}

.review-section .rating b {
    margin: 0px !important;
    height: 24px;
    width: 36px;
    text-align: center;
    line-height: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 5px;
}

.review-section .rating b.bg-success {
background: #C2F2D8 !important;
    color: #157742 !important;
}

.review-section .rating b.bg-warning {
    background: #f2f2c2 !important;
    color: #65660d !important;
}

.review-section .rating b.bg-danger {
    background: #fbd3d3 !important;
    color: #771515 !important;
}

.review-section .stars {
    gap: 4px;
    min-width: 82px;
}

.review-section .stars svg {
    height: 12px;
    width: auto;
    color: #BCA450 !important;
}

.travel-meta > div {
    flex: 1 !important;
}

.travel-meta > div.flex-0 {
    flex: 0 !important;
}

.travel-meta {
    width: 100%;
    min-width: 100%;
    padding: 20px 25px 0px;
}

.travel-meta > div,
.card-layout .card-body .flight-leg > div {
    gap: 10px;
    font-size: 14px;
    color: #666363;
    align-items: center;
    line-height: 1;
}

.travel-meta.breakfast {
    padding-top: 14px;
}

.travel-meta svg,
.card-layout .card-body .flight-leg svg {
    height: 12px;
    width: auto;
}

.card-layout .description {
    clear: both;
    float: left;
    width: 100%;
    border-bottom: 1px solid #E9E8E8;
    padding: 22px 25px 33px;
    display: flex;
    justify-content: center;
    align-items: start;
	position:relative;
}

.card-layout .description p,
.card-layout .testimonial p {
    font-size: 14px;
    color: #666363;
    margin: 0px;
    line-height: 24px;
}

.card-layout .description .btn-description-toggle {
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1A1414;
    border: 1px solid;
    background: #ffffff;
    position: absolute;
    bottom: -15px;
}

.card-layout .description .btn-description-toggle:hover {
	color:#f2bd1e;
}

.card-layout .testimonial {
    clear: both;
    float: left;
    width: 100%;
    padding-top: 33px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.card-layout .testimonial .testimonial-meta {
    gap: 10px;
}

.card-layout .testimonial .testimonial-meta img.custom-message-logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
}

.card-layout .testimonial .testimonial-meta span {
    font-size: 14px;
    color: #1a1a1a;
	line-height:0.8;
}

.card-layout .card-body h6, .card-layout .card-body .h6 {
    color: #1A1414;
    font-size: 14px;
    font-weight: 600;
    line-height: 0.8;
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.card-layout .card-body .flights,
.card-layout .card-body .hotels,
.card-layout .card-footer.hotels {
    padding: 22px 35px 0;
}

.card-layout .card-body .hotels,
.card-layout .card-footer.hotels {
	padding-bottom:22px;
}

.card-layout .card-body .flights h6,
.card-layout .card-body .hotels h6 {
}

.card-layout .card-body .flights h6 strong,
.card-layout .card-body .hotels h6 strong {
}

.card-layout .card-body .flights h6 small,
.card-layout .card-body .hotels h6 small {
}

.card-layout .card-body h6 small {
    font-size: 12px;
    opacity: 0.4;
}

.card-layout .card-body .flight-leg {
    margin-bottom: 0px !important;
    float: left;
    width: 100%;
    height: auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    color: #666363;
    font-size: 14px;
    line-height: 0.8;
}

.card-layout .card-body .btn,
.card-layout .card-footer .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.card-layout .description .btn-description-toggle {
    max-width: 30px;
}

.card-layout .card-body .hotels .travel-meta {
    padding: 0px 15px;
    min-width: 50%;
    width: 50%;
}

.card-layout .card-body .hotels .travel-meta svg {
    margin: 2px 0px 0px !important;
}

.card-layout .card-body .hotels .travel-meta > div {
    align-items: start !important;
}

.card-layout .card-body .hotels .travel-meta > div span {
    padding-top: 2px;
    line-height: 1.15;
    padding-top: 0px;
}

.card-layout .card-body .hotels .travel-meta svg.p-1.rounded {
    border-radius: 0px !important;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    padding: 2px !important;
    min-width: 12px;
}

.card-layout .card-body .column-buttons {
    margin-top: 20px;
}

.card-layout .card-body .hotels .btn,
.card-layout .card-body .flights-deal-btn .btn,
.card-layout .card-footer.hotels .btn {
    margin-top: 20px;
}

.card-layout .card-body .hotels,
.card-layout .card-footer.hotels  {
    padding-top: 25px;
}

.card-layout .card-body .hotels .btn-primary, 
.card-layout .card-body .flights-deal-btn .btn-primary,
.card-layout .card-footer.hotels .btn-primary {
    color: #1a1a1a !important;
}

.card-layout .description .btn-description-toggle {
    max-height: 30px;
    padding: 0px;
    justify-content: center;
}

.card-layout .card-body .btn span, .card-layout .card-body .btn div,
.card-layout .card-footer .btn span, .card-layout .card-footer .btn div {
    width: 100%;
}

.card-layout .card-body .flight-leg svg {
    margin: 0px !important;
}

.card-layout .card-body .flight-leg span {
    margin: 0px !important;
}

.card-layout .card-body .flight-leg .fa-arrow-right-long {
    opacity: 0.2;
    margin-right: 5px !important;
}

.card-layout .card-body .flight-leg .me-2 svg {
    margin: -4px 5px 0px 0px !important;
}

.card-layout .card-body .flight-leg span.me-4 {
    min-width: 35px;
}

.card-layout .card-body h6, .card-layout .card-body .h6 {
    margin-bottom: 14px;
}

.card-layout .card-body .hotels {
    padding-bottom: 25px;
}


.card-layout .card-footer.hotels {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0px;
    border: none;
    background: #ffffff;
}

.card-layout ul.benefits-list {
    padding: 0px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #666363;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 5px;
}

.card-layout ul.benefits-list li {
    color: #666363;
    padding: 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    min-width: 100%;
    gap: 10px;
}

.card-layout ul.benefits-list li svg {
    width: 14px;
    height: auto;
    margin-top: 2px;
    margin-right: 0px !important;
}


.border-top-flights {
    border-top: 1px solid #E9E8E8;
}

.card-layout .card-body .flights.flights-deal-meta {
    padding-left: 25px;
    padding-right: 25px;
}

.card-layout .flights-deal-btn {
    padding: 0px 25px 25px;
}

.image-repeater {
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
    align-items: start;
    justify-content: start;
}

.add-image-repeater-button {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.add-image-repeater-button button.btn {
    float: right;
}

.image-repeater .btn-image-upload,
.image-repeater .btn-remove-image {
    margin-top: 26px;
}

.image-repeater .btn-remove-image {
	margin-left:auto;
}

.image-repeater .image-input {
    flex: 1;
}

.image-repeater .image-full-preview {
    flex: 1;
    height: 130px;
    background: rgb(26 26 26 / 10%);
    border-radius: 7px;
    margin-top: 25px;
	overflow:hidden;
}

.image-repeater .image-full-preview img.w-100 {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
	object-position:center;
}

.card-layout .image-slider img.deal-card.airline-logo {
    object-fit: contain;
    padding: 50px;
}

.deals-overview > div {
	margin-bottom:15px;
}

.deals-overview .card-layout .card-body .flights.flights-deal-meta {
    padding-bottom: 25px;
}

.deals-overview .city-card .card-layout ul.benefits-list {
    margin-bottom: 25px;
}

.card-layout .card-body .column-buttons {
    display: flex;
    flex-direction: column;
}

.card-layout .card-body .column-buttons .btn-outline-dark {
    margin-top: 20px;
}

.trip-process-form.what-make-special p {
    font-size: 18px;
    font-weight: 600;
    margin: 0px 0px 7px 0px;
}

.slick-track .slick-slide, .slick-track .slick-slide > div, .slick-track .slick-slide > div > div {
    height: 100%;
}

.card-layout .image-slider .slick-slider {
    background:url(../../../public/assets/images/loader-spinner-gif.gif) center center no-repeat;
	background-color:#f5f5f5;
}

.card-layout .card-body span.spinner-border {
    width: 16px !important;
    height: 16px !important;
    max-width: 16px !important;
    max-height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
}


.app .profile-preferences {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding-top: 40px;
    gap: 20px;
}

.app .profile-preferences .opening-block {
    margin: 0px;
}

.app .profile-preferences .opening-block p {
    margin: 0px;
    width: 100%;
    font-size: 18px;
    line-height: 1.3;
}

.app .profile-preferences .profile-preference-columns {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.app .profile-preferences .profile-preference-columns form {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    float: left;
    width: 100%;
}

.app .profile-preferences .profile-preference-columns .splitter {
    clear: both;
    flex: 0 0 100%;
    min-width: 100%;
    height: 1px;
    display: flex;
    position: relative;
    justify-content: center;
}

.app .profile-preferences .profile-preference-columns .splitter:before {
    width: 100vw;
    content: "";
    height: 100%;
    position: absolute;
    top: 0px;
    background: hsl(0, 0%, 80%);
}

.app .profile-preferences .profile-preference-columns form .col {
    flex: 1 1;
    position: relative;
}

.app .profile-preferences .profile-preference-columns form .left-block:before {
    content: "";
    background: hsl(0, 0%, 80%);
    right: -25px;
    position: absolute;
    width: 1px;
    height: calc(100% + 100px);
    top: -50px;
}

.app .profile-preferences .profile-preference-columns form .col .fields label, .app .profile-preferences .profile-preference-columns form .col .fields p.mb-1,
.profile-preferences .fields.preference-star-rating p {
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px !important;
    line-height: 0.8;
}

.profile-preferences .fields .mb-3 {
    margin-bottom: 25px !important;
}

.app .profile-preferences .profile-preference-columns form .row {
	flex:0 0 100%;
	min-width:100%;
}

.profile-preferences .fields.room-selection p,
.profile-preferences .fields.long-haul-flights p.last {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 20px;
}

.profile-preferences .fields.room-selection h4 {
    font-size: 15px;
}

.app .profile-preferences .profile-preference-columns form .room-selection .row {
    margin-top: -3px;
}

.profile-preferences .fields.long-haul-flights .fa-circle-xmark,
.profile-preferences .fields.preferred-airport .fa-circle-xmark,
.profile-preferences .fields.long-haul-flights .fa-circle-plus,
.profile-preferences .fields.preferred-airport .fa-circle-plus {
    min-width: 38px;
    height: 38px;
    border-radius: 6px;
    box-shadow: 0px 0px 0px 8px #E57972 inset;
    padding: 3px;
    box-sizing: border-box;
}

.profile-preferences .fields.long-haul-flights .fa-circle-plus,
.profile-preferences .fields.preferred-airport .fa-circle-plus {
    box-shadow: 0px 0px 0px 8px #1a1414 inset;
}

.app .profile-preferences .profile-preference-columns .budget-deals {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.profile-preferences .budget-bar {
    clear: both;
    float: left;
    width: 100%;
    max-width: 750px;
}

.profile-preferences .budget-bar .px-2 {
    clear: both;
    float: left;
    width: 100%;
}

.profile-preferences .budget-bar p {
    float: left;
    width: 100%;
    display: block;
    font-family: "LuloCleanOne", sans-serif !important;
    text-align: center;
    font-weight: 600;
    color: #000000;
    margin: 0px;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 30px;
}

.profile-preferences .budget-bar span.rc-slider-mark-text {
    color: #000000;
    font-weight: 600;
}

.profile-preferences .fields {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.profile-preferences .deals-toggle {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-preferences .deals-toggle .form-check {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.profile-preferences .deals-toggle .form-check label {
    font-size: 18px;
    line-height: 1.3;
}

.profile-preferences .deals-full-width {
    position: relative;
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.profile-preferences .deals-full-width:before {
    content: "";
    background: #f5f5f5;
    position: absolute;
    top: 0px;
    width: 100vw;
    z-index: -1;
    height: 100%;
    border-top: 1px solid hsl(0, 0%, 80%);
}

.profile-preferences .deals-full-width {
    margin-bottom: -51px;
}

.app .profile-preferences .profile-preference-columns form .left-block {
	padding-right:25px;
}

.app .profile-preferences .profile-preference-columns form .right-block {
	padding-left:25px;
}

.profile-preferences .deals-toggle .form-check input {
    appearance: none;
    -webkit-appearance: none;
  width: 60px;
    background: #f5f5f5;
    border: 1px solid  #f5f5f5;
    height: 35px;
    border-radius: 30px;
    margin: 0px;
    position: relative;
    cursor: pointer;
}

.profile-preferences .deals-toggle .form-check input:before {
    content: "";
    width: 26px;
    height: 26px;
    background: #cccccc;
    position: absolute;
    right: 4px;
    border-radius: 50%;
    top: 4px;
}

.profile-preferences .deals-toggle .form-check input:checked {
	border:1px solid #000000;
	background:#ffffff;
}

.profile-preferences .deals-toggle .form-check input:checked:before {
    right: 28px;
    background: #000000;
}

.profile-preferences .deals-toggle .form-check input:hover:before {
	background:#888888;
}

.profile-preferences .deals-toggle .form-check input:checked:hover:before {
	background:#000000;
}

.profile-preferences .deals-full-width label.request-process-heading {
    margin: 0px !important;
    font-size: 16px;
    color: #000000;
}

.profile-preferences .deals-full-width .form-check-inline,
.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline {
    display: flex;
    gap: 10px;
    margin: 0px;
    padding: 0px;
    justify-content: start;
    align-items: center;
}

.profile-preferences .deals-full-width .form-check-inline label,
.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline label {
    font-size: 16px;
    line-height: 0.8;
    color: #000000;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline label {
color: #545454 !important;
}

.profile-preferences .deals-full-width .form-check-inline input,
.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline input {
    position: relative;
    margin: 0px;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #cccccc;
	cursor:pointer;
}

.profile-preferences .deals-full-width .form-check-inline:hover input,
.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline:hover input {
    border: 1px solid #000000;
}

.profile-preferences .deals-full-width .form-check-inline input:checked,
.profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline input:checked {
    border-color: #F2BD1E;
    background-size: 18px;
}

.profile-preferences .profile-preference-columns .preference-submit {
    padding: 0px;
    display: flex;
    justify-content: center;
}

.profile-preferences .profile-preference-columns .preference-submit button {
    height: 46px;
    min-width: 250px;
}

.profile-preferences .fields.favourite-month .mt-4 {
    margin: 0px !important;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .row {
    gap: 25px;
    margin: 0px;
    margin-top: 3px;
    row-gap: 15px;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .row .col {
    width: auto;
    padding: 0px;
    margin: 0px !important;
    flex: 0;
}

.app .profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline label {
    margin: 0px !important;
}

.profile-preferences .deals-toggle {
    position: relative;
}

.profile-preferences .deals-toggle span.option.yes {
    margin-left: -110px;
}

.profile-preferences .deals-toggle span.option.no {
    margin-right: -110px;
}

.profile-preferences .deals-toggle span.option {
    position: absolute;
    bottom: 10px;
    line-height: 0.8;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1em;
}

.profile-preferences .fields.preference-star-rating .mt-4 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px !important;
    gap: 5px;
}

.profile-preferences .fields.preference-star-rating p {
	min-width:100%;
	flex:0 0 100%;
}

.profile-preferences .fields.preference-star-rating p {
    margin-bottom: 10px !important;
}

.profile-preferences .fields.preference-star-rating .d-inline-block {
    margin: 0px !important;
}

.profile-preferences .fields.preference-star-rating svg {
    margin: 0px !important;
    font-size: 20px;
}

.star-rating-icon {
	cursor:pointer;
}

.travel-meta.gap-2 {
    gap: 10px !important;
    flex-wrap: wrap;
}

.travel-meta span.badge {
    padding: 8px 10px;
}

.card-layout.expired-deal,
.card-layout.expired-deal .image-slider,
.card-layout.expired-deal .card-footer {
    background: #f9f9f9;
}

.card-layout.expired-deal .image-slider img.card-img-top {
    filter: grayscale(1);
    opacity: 0.3;
}

.card-layout.expired-deal .expired-deal-overlay,
.card-layout .btn.disable-price-alerts {
    width: auto;
    position: absolute;
    z-index: 3;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 12px;
    border-radius: 6px;
    height: auto !important;
    line-height: 1;
    right: 12px;
    top: 12px;
}

.card-layout .btn.disable-price-alerts {
}

.card-layout.expired-deal h5 a {
    color: #999999;
    pointer-events: none;
    cursor: default;
}

.card-layout.expired-deal ul.benefits-list li {
    background: #f9f9f9;
}

.card-layout.expired-deal ul.benefits-list li svg,
.card-layout.expired-deal .travel-meta svg, .card-layout.expired-deal .flight-leg svg {
    color: #999999 !important;
}

.card-layout .card-footer.hotels .btn.btn-danger {
    margin-top: 10px;
}

.card-layout .btn.disable-price-alerts {
    right: auto;
    left: 12px;
    text-transform: capitalize !important;
}

.card-layout .btn.disable-price-alerts {
    font-size: 10px !important;
}


.table.blacklist-table {
    margin-bottom: 30px;
}

.table.blacklist-table th {
    padding: 0px;
    color: #141414;
    font-size: 14px;
    border-bottom: 2px solid;
    padding-bottom: 10px;
	padding-left:7px;
	padding-right:7px;
}

.table.blacklist-table td {
    padding: 15px 7px;
    border-bottom: 1px solid #dee2e6;
}

.table.blacklist-table th:first-of-type, .table.blacklist-table td:first-of-type {
    padding-left: 10px;
}

.table.blacklist-table th:last-of-type,
.table.blacklist-table td:last-of-type {
	padding-right:10px;
}


.table.blacklist-table tr:nth-of-type(even) td {
    background: rgb(222 226 230 / 15%);
}



.blacklist-btns .btn {
    min-width: 114px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
}

.table.blacklist-table textarea.form-control {
    resize: none;
    min-height: 100px;
    padding: 10px 15px;
}

.table.blacklist-table input.form-control {
    height: 40px;
    padding: 10px 15px;
}

.table.blacklist-table td.btn-container {
    width: 254px !important;
}

.table.blacklist-table td.text-area {
    width: 50% !important;
}

@media screen
and (max-width: 576px) {
	
.card-layout .image-slider {
    height: 260px;
}
.image-repeater {
    flex-wrap: wrap;
}

.image-repeater .image-input {
    flex: 0 0 100%;
}

.image-repeater .btn-image-upload, .image-repeater .btn-remove-image {
    margin-top: 0px;
	flex:1;
}

.image-repeater .btn-remove-image {
    margin-left: 0px;
    flex: 1;
}

.image-repeater .btn-image-upload {
    flex: 1;
}

.image-repeater .btn-remove-image button {
    width: 100%;
}

.trip-process-form {
    padding: 20px !important;
}

.trip-process-form form .mt-4.d-grid.gap-2 {
    display: flex !important;
    justify-content: space-between;
}

.trip-process-form form .mt-4.d-grid.gap-2 .btn-primary {
    margin-left: auto;
}

.holiday-type a.btn.btn-light-1 {
    float: left;
    min-width: 118px;
}

.holiday-type .row {
    margin-left: -7px;
    margin-right: -7px;
    row-gap: 15px;
    margin-bottom: 15px;
}

.container.holiday-type {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.container.holiday-type .col-6 {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.holiday-type .holiday-type-card .card-body {
    padding: 12px 15px;
}

.holiday-type .holiday-type-card .card-body .heading-text {
    font-size: 12px !important;
}

.trip-process-form .my-4.d-grid.gap-2 {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 30px 0 0 0 !important;
}

.trip-process-form .my-4.d-grid.gap-2 .btn {
    margin: 0px !important;
    min-width: 118px;
}

.trip-process-form form .mt-4.d-grid.gap-2 {
    flex-direction: row-reverse;
    clear: both;
    float: left;
    width: 100%;
}

.trip-process-form form .mt-4.d-grid.gap-2 .btn {
    margin: 0px !important;
    min-width: 118px;
}

.holiday-type .checked-holiday-type {
    top: 21%;
    left: 33%;
}

.add-room-btn {
    margin-top: 0px;
}

.room-selection .child-row .child-col {
    margin-bottom: 20px;
    min-width: 43%;
    max-width: 43%;
}

.trip-process-form .room-selection .invalid-feedback {
    display: none !important;
}

.trip-process-form .d-grid.gap-2.mt-5 {
    margin-top: 30px !important;
    justify-content: center;
}

.trip-process-form.what-make-special .row {
    row-gap: 12px;
}





.profile-preferences .deals-full-width {
    flex-wrap: wrap;
}

.profile-preferences .deals-full-width label.request-process-heading {
    min-width: 100%;
    text-align: center;
    margin-bottom: 2px !important;
}

.app .profile-preferences .opening-block p {
    font-size: 16px;
}

.profile-preferences .budget-bar p {
    font-size: 16px;
    margin-bottom: 25px;
}

.app .profile-preferences {
    gap: 15px;
    padding-top: 25px;
}

.app .profile-preferences .profile-preference-columns .budget-deals {
    gap: 25px;
}

.profile-preferences .deals-toggle .form-check label {
    font-size: 16px;
}

.profile-preferences .deals-toggle .form-check {
    gap: 12px;
}

.app .profile-preferences .profile-preference-columns form {
    gap: 30px;
}

.profile-preferences .deals-full-width {
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
gap: 10px;
}

    .profile-preferences .deals-full-width {
        margin-bottom: -30px;
    }

.app .profile-preferences .profile-preference-columns form .left-block {
    padding-right: 0px;
}

.app .profile-preferences .profile-preference-columns form .right-block {
    padding-left: 0px;
}

.app .profile-preferences .profile-preference-columns form .left-block:before {
    right: auto;
    height: 1px;
    top: auto;
    bottom: 0px;
    width: 100vw;
}

.profile-preferences .deals-full-width .form-check-inline label, .profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline label {
    font-size: 14px;
}

.app .profile-preferences .profile-preference-columns form .col .fields label, .app .profile-preferences .profile-preference-columns form .col .fields p.mb-1, .profile-preferences .fields.preference-star-rating p {
    font-size: 14px;
}

.app .profile-preferences .profile-preference-columns form .col {
    min-width: 100%;
    flex: 0 0 100%;
}

.profile-preferences .deals-full-width .form-check-inline {
    min-width: 47%;
}

.app .profile-preferences .profile-preference-columns form .col.left-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
}

.profile-preferences .fields.room-selection p, .profile-preferences .fields.long-haul-flights p.last {
    margin-bottom: 16px;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .row .col {
    min-width: 45%;
}

.profile-preferences .fields .mb-3 {
    margin-bottom: 20px !important;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .row {
    row-gap: 12px;
    margin-top: 0px;
}

.app .profile-preferences .profile-preference-columns form {
    justify-content: center;
}

.profile-preferences .deals-toggle .form-check {
    gap: 15px;
}

}

@media screen 
and (min-width: 577px)
and (max-width: 767px) {
	
.card-layout .image-slider {
    height: 200px;
}

.image-repeater {
    flex-wrap: wrap;
}

.image-repeater .image-input {
    flex: 0 0 100%;
}

.image-repeater .btn-image-upload, .image-repeater .btn-remove-image {
    margin-top: 0px;
	flex:1;
}

.image-repeater .btn-remove-image {
    margin-left: 0px;
    flex: 1;
}

.image-repeater .btn-image-upload {
    flex: 1;
}

.image-repeater .btn-remove-image button {
    width: 100%;
}

.trip-process-form form .mt-4.d-grid.gap-2 {
     display: block !important; 
}

.trip-process-form {
    padding: 20px !important;
}

.trip-process-form form .mt-4.d-grid.gap-2 {
    display: flex !important;
    justify-content: space-between;
}

.trip-process-form form .mt-4.d-grid.gap-2 .btn-primary {
    margin-left: auto;
}

.holiday-type a.btn.btn-light-1 {
    float: left;
    min-width: 118px;
}

.holiday-type .row {
    margin-left: -7px;
    margin-right: -7px;
    row-gap: 15px;
    margin-bottom: 15px;
}

.container.holiday-type {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.container.holiday-type .col-6 {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.holiday-type .holiday-type-card .card-body {
    padding: 12px 15px;
}

.holiday-type .holiday-type-card .card-body .heading-text {
    font-size: 12px !important;
}

.trip-process-form .my-4.d-grid.gap-2 {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 30px 0 0 0 !important;
}

.trip-process-form .my-4.d-grid.gap-2 .btn {
    margin: 0px !important;
    min-width: 118px;
}

.trip-process-form form .mt-4.d-grid.gap-2 {
    flex-direction: row-reverse;
    clear: both;
    float: left;
    width: 100%;
}

.trip-process-form form .mt-4.d-grid.gap-2 .btn {
    margin: 0px !important;
    min-width: 118px;
}

.holiday-type .checked-holiday-type {
    top: 21%;
    left: 33%;
}

.add-room-btn {
    margin-top: 0px;
}

.room-selection .child-row .child-col {
    margin-bottom: 20px;
    min-width: 43%;
    max-width: 43%;
}

.trip-process-form .room-selection .invalid-feedback {
    display: none !important;
}

.trip-process-form .d-grid.gap-2.mt-5 {
    margin-top: 30px !important;
    justify-content: center;
}

.trip-process-form.what-make-special .row {
    row-gap: 12px;
}




.profile-preferences .deals-full-width {
    flex-wrap: wrap;
}

.profile-preferences .deals-full-width label.request-process-heading {
    min-width: 100%;
    text-align: center;
    margin-bottom: -4px !important;
}

.app .profile-preferences .opening-block p {
    font-size: 16px;
}

.profile-preferences .budget-bar p {
    font-size: 16px;
    margin-bottom: 25px;
}

.app .profile-preferences {
    gap: 15px;
    padding-top: 30px;
}

.app .profile-preferences .profile-preference-columns .budget-deals {
    gap: 25px;
}

.profile-preferences .deals-toggle .form-check label {
    font-size: 16px;
}

.profile-preferences .deals-toggle .form-check {
    gap: 12px;
}

.app .profile-preferences .profile-preference-columns form {
    gap: 35px;
}

.profile-preferences .deals-full-width {
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 15px;
}

    .profile-preferences .deals-full-width {
        margin-bottom: -35px;
    }

.app .profile-preferences .profile-preference-columns form .left-block {
    padding-right: 0px;
}

.app .profile-preferences .profile-preference-columns form .right-block {
    padding-left: 0px;
}

.app .profile-preferences .profile-preference-columns form .left-block:before {
    right: auto;
    height: 1px;
    top: auto;
    bottom: 0px;
    width: 100vw;
}

.profile-preferences .deals-full-width .form-check-inline label, .profile-preferences .profile-preference-columns .fields.favourite-month .form-check-inline label {
    font-size: 14px;
}

.app .profile-preferences .profile-preference-columns form .col .fields label, .app .profile-preferences .profile-preference-columns form .col .fields p.mb-1, .profile-preferences .fields.preference-star-rating p {
    font-size: 14px;
}

.app .profile-preferences .profile-preference-columns form .col {
    min-width: 100%;
    flex: 0 0 100%;
}

.profile-preferences .deals-full-width .form-check-inline {
    min-width: 47%;
}

.app .profile-preferences .profile-preference-columns form .col.left-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 35px;
}

.profile-preferences .fields.room-selection p, .profile-preferences .fields.long-haul-flights p.last {
    margin-bottom: 16px;
}

.profile-preferences .profile-preference-columns .fields.favourite-month .row .col {
    min-width: 47%;
}

.app .profile-preferences .profile-preference-columns form {
    justify-content: center;
}

.profile-preferences .deals-toggle .form-check {
    gap: 15px;
}

}

@media screen 
and (min-width: 768px)
and (max-width: 991px) {

.card-layout .image-slider {
    height: 220px;
}

.image-repeater {
    flex-wrap: wrap;
}

.image-repeater .image-input {
    flex: 0 0 100%;
}

.image-repeater .btn-image-upload, .image-repeater .btn-remove-image {
    margin-top: 0px;
	flex:1;
}

.image-repeater .btn-remove-image {
    margin-left: 0px;
    flex: 1;
}

.image-repeater .btn-image-upload {
    flex: 1;
}

.image-repeater .btn-remove-image button {
    width: 100%;
}



.profile-preferences .deals-full-width {
    flex-wrap: wrap;
}

.profile-preferences .deals-full-width label.request-process-heading {
    min-width: 100%;
    text-align: center;
    margin-bottom: -10px !important;
}

.app .profile-preferences .opening-block p {
    font-size: 16px;
}

.profile-preferences .budget-bar p {
    font-size: 16px;
    margin-bottom: 25px;
}

.app .profile-preferences {
    gap: 15px;
    padding-top: 30px;
}

.app .profile-preferences .profile-preference-columns .budget-deals {
    gap: 25px;
}

.profile-preferences .deals-toggle .form-check label {
    font-size: 16px;
}

.profile-preferences .deals-toggle .form-check {
    gap: 12px;
}

.app .profile-preferences .profile-preference-columns form {
    gap: 40px;
}

.profile-preferences .deals-full-width {
    margin-top: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.profile-preferences .deals-full-width {
    margin-bottom: -41px;
}

.app .profile-preferences .profile-preference-columns form .left-block {
    padding-right: 10px;
}

.app .profile-preferences .profile-preference-columns form .right-block {
    padding-left: 10px;
}

.app .profile-preferences .profile-preference-columns form .left-block:before {
    height: calc(100% + 80px);
    top: -40px;
    right: -20px;
}

}

@media screen 
and (min-width: 992px)
and (max-width: 1200px) {
	
    .card-layout .image-slider {
        height: 200px;
    }


.profile-preferences .deals-full-width {
    flex-wrap: wrap;
}

.profile-preferences .deals-full-width label.request-process-heading {
    min-width: 100%;
    text-align: center;
    margin-bottom: -10px !important;
}

.card-layout .location-top .pricing strong {
    font-size: 25px;
}

.card-layout .btn {
    font-size: 10px !important;
}

.card-layout .location-top .pricing {
    flex-wrap: wrap;
}

.card-layout .location-top .pricing small {
    font-size: 12px;
    flex: 0 0 100%;
    width: 100%;
    margin-top: 2px;
    padding-left: 52px;
}

    .card-layout .card-footer .btn {
        height: 36px;
        font-size: 10px !important;
    }

}


@media screen and (min-width: 1201px)
and (max-width: 1280px) {
.navbar-nav {
    justify-content: center;
    gap: 13px;
}

.main-navbar .nav-link.heading-text {
    font-size: 12px !important;
}

.card-layout .image-slider {
    height: 230px;
}
}

.star-rating-icon {
	color:#000000;
}


.star-rating-icon.selected, .star-rating-icon:hover {
    color: #f2bd1e;
}
