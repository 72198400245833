.rc-slider-handle {
  width: 16px !important;
  height: 16px !important;
  background-color: $primary !important;
  border: solid 2px $primary !important;
  opacity: 1 !important;
  &:hover {
    background-color: rgba(var($primary), 0.4) !important;
  }
}

.rc-slider-handle-dragging {
  &.rc-slider-handle-dragging {
    &.rc-slider-handle-dragging {
      border-color: $primary !important;
      box-shadow: 0 0 0 5px rgba($primary, .5) !important;
    }
  }
}

.rc-slider-track {
  background-color: $primary !important;
}

.rc-slider {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  height: 8px !important;
  padding: 0 !important;

  .rc-slider-rail {
    height: 8px !important;
    background-color: $white;
  }

  .rc-slider-track {
    height: 8px !important;
  }

  .rc-slider-dot {
    width: 16px !important;
    height: 16px !important;
    bottom: -8px !important;
  }
}

.rc-slider-dot-active
{
border-color: $primary !important;
}