.flight-logos {
  .airline-logo {
    width: 100%;
    background-color: white !important;
  }

  .col {
    height: 220px !important;
  }

  .bg-0 {
    background: #083590 !important;
  }

  .bg-1 {
    background: #FF6600 !important;
  }

  .p-10 {
    padding: 40px !important;
  }

}

.select-card {
  &.selected {
    .card {
      border: 2px solid $primary;
    }
  }
}

.card {
  &.selected {
    border: 4px solid $primary;
  }
}

.card-heading-price
{
  font-size: 14px;
}

.expired-deal img {
  opacity: 0.5;
}

.expired-deal-overlay {
  width: 100%;
  object-fit: cover;
  transition: all ease-in 0.2s;
  height: 220px !important;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
}