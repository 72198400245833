.rounded-icon {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  z-index: 1;
}

.checked {
  background-color: $primary !important;
}

.unchecked {
  border: 1px solid #CED4DA;
  background-color: $white;
}

.trip-progress-container {
  max-width: 215px;

  .trip-progress {
    position: absolute;
    top: 14px;
    width: 100%;

    .trip-progress-line {
      border-top: 1px solid #CED4DA;
    }
  }
}

.free-trip-progress-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.trip-process-form {
  width: 100%;
  background-color: $white;
}

.sm-bg {
  height: 235px;
}

.remove-icon {
  font-size: 30px;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.holiday-type {
  max-width: 800px !important;

  .holiday-type-card {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
    border-radius: 4px 4px 0 0;
    border: 0 !important;
  }

  .checked-holiday-type {
    position: absolute;
    font-size: 60px;
    color: $primary;
    z-index: 1;
    top: 30%;
    left: 40%;

  }
}

@media (min-width: 576px) {
  .trip-process-form {
    width: 550px;
  }
}