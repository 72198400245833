.card-img-top {
  height: 130px;
  width: 100%;
  object-fit: cover;
  transition: all ease-in 0.2s;

  &.deal-card {
    height: 220px !important;
  }
}


@media (min-width: 576px) {
  .card-img-top {
    height: 220px !important;
  }
}