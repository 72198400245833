.progress-bars
{
  height: 70px !important;
  min-height: 70px !important;
  .apexcharts-canvas
  {
    height: 70px !important;
    overflow: hidden;
  }
  .apexcharts-inner
  {
    position: absolute;
    top: 0 !important;
    transform: translate(33.63535118103027px, 5%) !important;
  }
}