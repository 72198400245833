.main-navbar {
    height: 61px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

    .navbar-nav {
        .nav-link {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $dark;
        }
    }

    .nav-link
    {
        &.heading-text {
            font-size: 14px !important;
        }
    }
}

.mobile-menu-cover
{
    position: fixed;
    top:61px;
    width: 100vw;
    height: calc(100vh - 61px);
    background-color: $white;
    z-index: 1;
    display: none;
    opacity: 0;
    transition: all 200ms ease-in;

    &.show {
        display: block;
        opacity: 1;
        padding-top: 30px !important;
        transition: all 200ms ease-in;
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
}

.notification-badge
{
    position: absolute;
    right: -5px;
    top: -2px;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 100%;
    background-color: $danger;
    color: $white;
    font-weight: bold;
    font-size: 0.675em;
}