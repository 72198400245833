.rmdp-calendar
{
  padding: 0 !important;
  .rmdp-header
  {
    border-bottom: 1px solid #CED4DA;
  }
}
.rmdp-shadow
{
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15) !important;
}

.rmdp-wrapper
{
  transition: all ease-in 0.2s;
}

.rmdp-top-class
{
  display: block !important;
}

.rmdp-day-picker
{
  display: block !important;
}

.rmdp-day{
  &.rmdp-selected {
    span {
      &:not(.highlight){
        background-color: $primary !important;
        box-shadow: none !important;
        border-radius: 2px !important;
        color: $white !important;
      }
    }
  }
  &.rmdp-today {
    span {
      background-color: $white !important;
      border: 1px solid $primary !important;
      color: $dark !important;
      border-radius: 2px !important;
    }
  }
  &:not(.rmdp-disabled){
    &:not(.rmdp-day-hidden) {
      span{
        &:hover {
          background-color: $primary !important;
          color: $white !important;
          border-radius: 2px !important;
        }
      }
    }
  }
}

.rmdp-range{
  background-color: $primary !important;
  box-shadow: 0 0 3px rgba(var(--bs-primary-rgb), .6) !important;
}

@media (max-width: 575px) {
  .rmdp-shadow
  {
    box-shadow: none !important;
  }
}

@media (max-width: 450px), (min-width: 386px) {
  .rmdp-day, .rmdp-week-day {
    height: 45px !important;
    width: 45px !important;
    margin: 5px !important;
  }
  .rmdp-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 385px) {
  .rmdp-day, .rmdp-week-day {
    height: 30px !important;
    width: 30px !important;
    margin: 0 5px !important;
  }
  .rmdp-wrapper {
    width: 100% !important;
  }
}