:not(.btn-check) {
  + .btn:hover {

  }
}

.btn:first-child {
  &.btn-outline-primary {
    &:hover {
      color: $white !important;
    }
  }
}

.btn-success {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.btn-primary {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.btn-light {
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.btn-link
{
  text-decoration: none !important;
}

.btn-light-1 {
  background-color: #F4F4F4 !important;
}

.paypal-button
{
  width: 100px;
}

.paypal-button-tab-title
{
  width: 100px;
}

@media (max-width: 768px) {
  .month-btn {
    font-size: 14px !important;
  }
}

.btn-logout{
  background: var(--bs-btn-hover-bg) !important;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  color: var(--bs-btn-hover-color);
}

.btn-description-toggle {
  border: 1px solid #FFC107FF;
  color: #FFC107FF;
  border-radius: 200px;
}