$primary: #F2BD1E;
$warning: #FFC107;
$danger: #E57972;
$success: #28A745;
$light: #CED4DB;

$dark: #1A1414;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "fonts";
@import "layout";
@import "style";

@import "partials/buttons";
@import "partials/inputs";
@import "partials/spinner";


@import "partials/auth";
@import "partials/navbar";
@import "partials/travelArea";
@import "partials/trips";
@import "partials/notifications";
@import "partials/tripCalender";
@import "partials/modal";
@import "partials/select";
@import "partials/card";
@import "partials/deals";
@import "partials/profile";
@import "partials/rcSlider";
@import "partials/proSideBar";
@import "partials/reactBoostrapTable";
@import "partials/users";
@import "partials/breadcrumb";
@import "partials/charts";
@import "partials/tripRequests";

.main-navbar .nav-link.heading-text {
    font-size: 12px !important;
}


.star-rating-icon {
	color:#000000;
}


.star-rating-icon.selected, .star-rating-icon:hover {
    color: #f2bd1e;
}
